import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PDFDamageRelatedScreeningOnlineTypeBlockMain = _resolveComponent("PDFDamageRelatedScreeningOnlineTypeBlockMain")!
  const _component_PDFOnlineTypeBlockInformation = _resolveComponent("PDFOnlineTypeBlockInformation")!
  const _component_PDFDamageRelatedScreeningOnlineTypeBlockTestResults = _resolveComponent("PDFDamageRelatedScreeningOnlineTypeBlockTestResults")!
  const _component_PDFOnlineTypeBlockSampleImages = _resolveComponent("PDFOnlineTypeBlockSampleImages")!
  const _component_PDFOnlineTypeBlockWorkDescription = _resolveComponent("PDFOnlineTypeBlockWorkDescription")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isAllowed('PDFDamageRelatedScreeningOnlineTypeBlockMain'))
      ? (_openBlock(), _createBlock(_component_PDFDamageRelatedScreeningOnlineTypeBlockMain, {
          key: 0,
          categoryItem: _ctx.categoryItem,
          context: _ctx.context,
          titles: _ctx.titles,
          encodedTitles: _ctx.encodedTitles,
          sectionNumber: _ctx.sectionNumber,
          disable: _ctx.getDisableConfig('PDFDamageRelatedScreeningOnlineTypeBlockMain')
        }, null, 8, ["categoryItem", "context", "titles", "encodedTitles", "sectionNumber", "disable"]))
      : _createCommentVNode("", true),
    (_ctx.isAllowed('PDFOnlineTypeBlockInformation'))
      ? (_openBlock(), _createBlock(_component_PDFOnlineTypeBlockInformation, {
          key: 1,
          oType: _ctx.oType,
          titles: _ctx.titles,
          encodedTitles: _ctx.encodedTitles,
          disable: _ctx.getDisableConfig('PDFOnlineTypeBlockInformation')
        }, null, 8, ["oType", "titles", "encodedTitles", "disable"]))
      : _createCommentVNode("", true),
    (_ctx.isAllowed('PDFDamageRelatedScreeningOnlineTypeBlockTestResults'))
      ? (_openBlock(), _createBlock(_component_PDFDamageRelatedScreeningOnlineTypeBlockTestResults, {
          key: 2,
          oType: _ctx.oType,
          context: _ctx.context,
          titles: _ctx.titles,
          encodedTitles: _ctx.encodedTitles,
          disable: _ctx.getDisableConfig('PDFDamageRelatedScreeningOnlineTypeBlockTestResults')
        }, null, 8, ["oType", "context", "titles", "encodedTitles", "disable"]))
      : _createCommentVNode("", true),
    (_ctx.isAllowed('PDFOnlineTypeBlockSampleImages'))
      ? (_openBlock(), _createBlock(_component_PDFOnlineTypeBlockSampleImages, {
          key: 3,
          oType: _ctx.oType,
          context: _ctx.context,
          titles: _ctx.titles,
          encodedTitles: _ctx.encodedTitles,
          disable: _ctx.getDisableConfig('PDFOnlineTypeBlockSampleImages')
        }, null, 8, ["oType", "context", "titles", "encodedTitles", "disable"]))
      : _createCommentVNode("", true),
    (_ctx.isAllowed('PDFOnlineTypeBlockWorkDescription'))
      ? (_openBlock(), _createBlock(_component_PDFOnlineTypeBlockWorkDescription, {
          key: 4,
          oType: _ctx.oType,
          titles: _ctx.titles,
          encodedTitles: _ctx.encodedTitles,
          disable: _ctx.getDisableConfig('PDFOnlineTypeBlockWorkDescription')
        }, null, 8, ["oType", "titles", "encodedTitles", "disable"]))
      : _createCommentVNode("", true)
  ], 64))
}