import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PDFDamageRelatedScreeningOnlineType = _resolveComponent("PDFDamageRelatedScreeningOnlineType")!
  const _component_PDFPage = _resolveComponent("PDFPage")!
  const _component_PDFPageRender = _resolveComponent("PDFPageRender")!

  return (_openBlock(), _createBlock(_component_PDFPageRender, { disableAutoRender: false }, {
    default: _withCtx(({ pageFilled, pageStartIndexes, pageElements, grapSlottedElements }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pageStartIndexes, (startIndex) => {
        return (_openBlock(), _createBlock(_component_PDFPage, {
          key: startIndex,
          class: "PDFCategories",
          context: _ctx.context,
          elementsList: pageElements,
          startIndex: Number(startIndex) || 0,
          onPageIsFull: pageFilled,
          onElementsLoaded: grapSlottedElements
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.populatedCategories, (category) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: category.hashID
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryTOC(category.onlineTypes.directlyRelated), (item) => {
                  return (_openBlock(), _createBlock(_component_PDFDamageRelatedScreeningOnlineType, {
                    key: item.oType.hashID,
                    sectionNumber: _ctx.section,
                    context: _ctx.context,
                    titles: {
                section: _ctx.sectionTitle,
                category: _ctx.getTitle(category.title),
                type: _ctx.getTitle(item.oType.titles.complete)
              },
                    encodedTitles: {
                section: _ctx.mixEncodeString(_ctx.sectionTitle),
                category: _ctx.getEncodedTitle(category.title),
                type: _ctx.getEncodedTitle(item.oType.titles.complete)
              },
                    categoryItem: item
                  }, null, 8, ["sectionNumber", "context", "titles", "encodedTitles", "categoryItem"]))
                }), 128))
              ], 64))
            }), 128))
          ]),
          _: 2
        }, 1032, ["context", "elementsList", "startIndex", "onPageIsFull", "onElementsLoaded"]))
      }), 128))
    ]),
    _: 1
  }))
}