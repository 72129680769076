
import Image from '@/components/Image.vue'
import PDFFloorplanBlock from '@/components/pdfs/partials/PDFFloorplanBlock.vue'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import { proProjectsStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PDFOnlineTypeSampleImage',
  props: {
    sample: {
      type: Object as PropType<ProSample>,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    titleTag: {
      type: String,
      required: false,
      default: 'h3',
    },
    context: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(proProjectsStore, ['allSamples']),
    referenceSample() {
      if (this.sample.options.isInternalEquivalent) {
        return this.sample.getReferenceSample()
      }

      return this.sample
    },
  },
  methods: {
    getSampleImage(sample: ProSample) {
      if (['assessed', 'postponed', 'existing'].includes(sample.sampleType)) {
        return null
      }

      // If sample is from other project - get images from original sample
      if (sample.otherScreeningID) {
        const relatedSample = this.allSamples.find(
          (s) => s.id === sample.equivalentSampleID
        )

        const { close } = relatedSample?.images ?? {
          close: null,
        }

        return close
      }

      // Use current samples images
      const { close } = sample.getReferenceSample().images

      return close
    },
  },
  components: { PDFFloorplanBlock, Image },
})
