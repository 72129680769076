
import PDFDamageRelatedScreeningOnlineTypeBlockMain from '@/components/pdfs/partials/PDFDamageRelatedScreeningOnlineTypeBlockMain.vue'
import PDFOnlineTypeBlockInformation from '@/components/pdfs/partials/PDFOnlineTypeBlockInformation.vue'
import PDFOnlineTypeBlockSampleImages from '@/components/pdfs/partials/PDFOnlineTypeBlockSampleImages.vue'
import PDFDamageRelatedScreeningOnlineTypeBlockTestResults from '@/components/pdfs/partials/PDFDamageRelatedScreeningOnlineTypeBlockTestResults.vue'
import PDFOnlineTypeBlockWorkDescription from '@/components/pdfs/partials/PDFOnlineTypeBlockWorkDescription.vue'
import { ICatTOCItem } from '@/components/pdfs/sections/general/PDFCategories.vue'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PDFDamageRelatedScreeningOnlineType',
  props: {
    categoryItem: {
      type: Object as PropType<ICatTOCItem>,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
    sectionNumber: {
      type: Number,
      required: true,
    },
    titles: {
      type: Object as PropType<{
        section: string
        category: string
        type: string
      }>,
      required: true,
    },
    encodedTitles: {
      type: Object as PropType<{
        section: string
        category: string
        type: string
      }>,
      required: true,
    },
  },
  data() {
    return {
      oType: this.categoryItem.oType,
      activeElements: [] as string[],
    }
  },
  computed: {
    firstBlockName() {
      return this.activeElements[0]
    },
    lastBlockName() {
      return this.activeElements.slice(-1)[0]
    },
    hasPostponedSample() {
      return (
        this.oType.samples.some((x) => x.sampleType === 'postponed') ?? false
      )
    },
    hasNoSamples() {
      return this.oType.samples.length === 0
    },
    workDescriptionsTexts() {
      return this.oType.getWorkdescriptionTexts()
    },
    infoTexts() {
      return this.oType.getInformationTexts()
    },
  },
  methods: {
    getAllowedComponents() {
      const list = [
        'PDFDamageRelatedScreeningOnlineTypeBlockMain',
        'PDFOnlineTypeBlockInformation',
        'PDFDamageRelatedScreeningOnlineTypeBlockTestResults',
        'PDFOnlineTypeBlockSampleImages',
        'PDFOnlineTypeBlockWorkDescription',
      ]

      const removeFromList = (name: string) => {
        const index = list.findIndex((item) => item === name)

        if (index > -1) {
          list.splice(index, 1)
        }
      }

      // 1. No information text
      if (!this.infoTexts?.length) {
        removeFromList('PDFOnlineTypeBlockInformation')
      }

      // 2. No workdescription text
      if (!this.workDescriptionsTexts?.length) {
        removeFromList('PDFOnlineTypeBlockWorkDescription')
      }

      // 3. If postponed samples
      if (this.hasPostponedSample) {
        removeFromList('PDFDamageRelatedScreeningOnlineTypeBlockTestResults')
        removeFromList('PDFOnlineTypeBlockSampleImages')

        return list
      }

      // 4. If no samples
      if (this.hasNoSamples) {
        removeFromList('PDFDamageRelatedScreeningOnlineTypeBlockTestResults')
        removeFromList('PDFOnlineTypeBlockSampleImages')

        return list
      }

      // 5. If no standard samples related to online type
      const noStandardSamples = !this.oType.samples.some((x) => x.sampleNumber)
      const noInternalEquivalent = !this.oType.samples.some(
        (x) => x.options.isInternalEquivalent
      )

      if (noStandardSamples && noInternalEquivalent) {
        removeFromList('PDFOnlineTypeBlockSampleImages')
      }

      // 6. If only one sample related to online type
      if (this.oType.samples.length === 1) {
        removeFromList('PDFOnlineTypeBlockSampleImages')
      }

      return list
    },
    getDisableConfig(name: string) {
      return {
        continued: this.firstBlockName === name,
        toBeContinued: this.lastBlockName === name,
      }
    },
    isAllowed(name: string) {
      return this.activeElements.includes(name)
    },
  },
  components: {
    PDFDamageRelatedScreeningOnlineTypeBlockMain,
    PDFOnlineTypeBlockInformation,
    PDFDamageRelatedScreeningOnlineTypeBlockTestResults,
    PDFOnlineTypeBlockWorkDescription,
    PDFOnlineTypeBlockSampleImages,
  },
  created() {
    this.activeElements = this.getAllowedComponents()
  },
})
