
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { currentStore, pdfStore } from '@/store'
import PDFPreview from '@/components/pdfs/PDFPreview.vue'
import PDFFrontpage from '@/components/pdfs/sections/general/PDFFrontpage.vue'
import PDFPage from '@/components/pdfs/PDFPage.vue'
import PDFComments from '@/components/pdfs/sections/general/PDFComments.vue'
import PDFCategories from '@/components/pdfs/sections/damageRelatedScreening/PDFCategories.vue'

export default defineComponent({
  name: 'DamageRelatedScreeningReport',
  props: {
    reference: {
      type: String,
      default: '',
    },
    initFetch: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(pdfStore, ['configs']),
    ...mapState(currentStore, {
      reportNotes: (store) => (store.project?.data.reportNotes ? 1 : 0),
    }),
    key() {
      return this.reference || this.$route.query.reference
    },
    pdf() {
      const reference =
        this.reference || (this.$route.query.reference as string)

      return reference
        ? this.configs.reports[reference]
        : this.configs.reports.damageRelatedScreeningReport
    },
    initiatePDFRequest() {
      // 1. Ensure fetch is requested && pdf have been loaded in DOM
      return this.initFetch
    },
  },
  watch: {
    initFetch: {
      handler(bool) {
        // Mimic the initial process from PDFPreview when the DOM is building
        if (bool) {
          this.statusChanged('process:creating-pdf')
        }
      },
      immediate: true,
    },
  },
  methods: {
    statusChanged(str: string) {
      this.$emit('status-changed', {
        reportType: this.pdf.reference,
        status: str,
      })
    },
  },
  components: {
    PDFPreview,
    PDFFrontpage,
    PDFPage,
    PDFComments,
    PDFCategories,
  },
})
