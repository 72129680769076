import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ImageContainer" }
const _hoisted_2 = {
  key: 1,
  class: "PDFOnlineTypeBlockSampleImages"
}
const _hoisted_3 = { class: "ImageContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PDFOnlineTypeSampleImage = _resolveComponent("PDFOnlineTypeSampleImage")!
  const _component_PDFRegion = _resolveComponent("PDFRegion")!
  const _component_PDFOnlineTypeBlockWrap = _resolveComponent("PDFOnlineTypeBlockWrap")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chunkedSamples, (chunk, index) => {
    return (_openBlock(), _createBlock(_component_PDFOnlineTypeBlockWrap, {
      key: index,
      titles: _ctx.titles,
      encodedTitles: _ctx.encodedTitles,
      disableContinued: _ctx.disable.continued,
      disableToBeContinued: _ctx.disable.toBeContinued && index + 1 >= _ctx.chunkedSamples.length
    }, {
      default: _withCtx(() => [
        (index === 0)
          ? (_openBlock(), _createBlock(_component_PDFRegion, {
              key: 0,
              class: "PDFOnlineTypeBlockSampleImages",
              heading: _ctx.mixWB('SAMPLING')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chunk, (sample, index) => {
                    return (_openBlock(), _createBlock(_component_PDFOnlineTypeSampleImage, {
                      key: sample.hashID + index,
                      title: _ctx.getSampleTitle(sample),
                      sample: sample,
                      context: _ctx.context
                    }, null, 8, ["title", "sample", "context"]))
                  }), 128))
                ])
              ]),
              _: 2
            }, 1032, ["heading"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relevantSamples, (sample, index) => {
                  return (_openBlock(), _createBlock(_component_PDFOnlineTypeSampleImage, {
                    key: sample.hashID + index,
                    title: _ctx.getSampleTitle(sample),
                    sample: sample,
                    context: _ctx.context
                  }, null, 8, ["title", "sample", "context"]))
                }), 128))
              ])
            ]))
      ]),
      _: 2
    }, 1032, ["titles", "encodedTitles", "disableContinued", "disableToBeContinued"]))
  }), 128))
}