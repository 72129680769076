
import PDFOnlineTypeBlockWrap from '@/components/pdfs/partials/PDFOnlineTypeBlockWrap.vue'
import PDFOnlineTypeSampleImage from '@/components/pdfs/partials/PDFOnlineTypeSampleImage.vue'
import PDFRegion from '@/components/pdfs/partials/PDFRegion.vue'
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import Unit from '@/globals/javascript/models/proUnit/Unit'
import { currentStore } from '@/store'
import { chunk, uniqBy } from 'lodash'
import { mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PDFOnlineTypeBlockSampleImages',
  props: {
    oType: {
      type: Object as PropType<OnlineType>,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
    titles: {
      type: Object as PropType<{
        section: string
        category: string
        type: string
      }>,
      required: true,
    },
    encodedTitles: {
      type: Object as PropType<{
        section: string
        category: string
        type: string
      }>,
      required: true,
    },
    disable: {
      type: Object as PropType<{ continued: boolean; toBeContinued: boolean }>,
      required: true,
    },
  },
  data() {
    return {
      disableToBeContinued: false,
    }
  },
  computed: {
    ...mapState(currentStore, ['samples']),
    relevantSamples() {
      const relevantSamples = this.oType.samples.map((s) => {
        if (s.sampleNumber) {
          return s
        }

        if (s.options.isInternalEquivalent) {
          return s.getReferenceSample()
        }

        return null
      })

      return relevantSamples.filter(Boolean) as ProSample[]
    },
    chunkedSamples() {
      // const dummyArray = [
      //   ...this.relevantSamples,
      //   ...this.relevantSamples,
      //   ...this.relevantSamples,
      // ]

      // return chunk(dummyArray, 2)

      const uniqueSamples = uniqBy(this.relevantSamples, 'id')
      return chunk(uniqueSamples, 2)
    },
  },
  methods: {
    getUnit(sample: ProSample) {
      const { floorPlanUnit } = sample

      // If no floor plan is assigned
      if (!floorPlanUnit) {
        return null
      }

      return Unit.getUnitById(floorPlanUnit)
    },
    getSampleTitle(sample: ProSample) {
      const { sampleTypeID } = sample

      const unit = this.getUnit(sample)

      if (unit) {
        return `${sampleTypeID} (${unit.getFullTitle()})`
      }

      return sampleTypeID
    },
  },
  components: {
    PDFOnlineTypeBlockWrap,
    PDFRegion,
    PDFOnlineTypeSampleImage,
  },
})
