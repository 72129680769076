
import PDFOnlineTypeBlockWrap from '@/components/pdfs/partials/PDFOnlineTypeBlockWrap.vue'
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { chunk } from 'lodash-es'
import { defineComponent, PropType } from 'vue'
import PDFRegion from '@/components/pdfs/partials/PDFRegion.vue'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import { mapState } from 'pinia'
import { currentStore, proProjectsStore, resourcesStore } from '@/store'
import versionKeys from '@/globals/javascript/constants/versionKeys'
import { compare } from 'compare-versions'
import PDFOnlineTypeSampleImage from '@/components/pdfs/partials/PDFOnlineTypeSampleImage.vue'

export default defineComponent({
  name: 'PDFDamageRelatedScreeningOnlineTypeBlockTestResults',
  inheritAttrs: false,
  props: {
    oType: {
      type: Object as PropType<OnlineType>,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
    titles: {
      type: Object as PropType<{
        section: string
        category: string
        type: string
      }>,
      required: true,
    },
    encodedTitles: {
      type: Object as PropType<{
        section: string
        category: string
        type: string
      }>,
      required: true,
    },
    disable: {
      type: Object as PropType<{ continued: boolean; toBeContinued: boolean }>,
      required: true,
    },
  },
  data() {
    return {
      chunkedSamples: chunk(this.oType.samples, 3),
    }
  },
  computed: {
    ...mapState(proProjectsStore, ['allSamples']),
    ...mapState(resourcesStore, ['cleanAnalysisTestsAsArray']),
    ...mapState(currentStore, ['samples', 'projectProVersion']),
    chunkedAnalysesArray() {
      return [
        this.cleanAnalysisTestsAsArray.slice(0, 7),
        ...this.cleanAnalysisTestsAsArray.slice(7),
      ]
    },
    results() {
      const results = this.oType.samples.reduce(
        (prev, sample: ProSample, index) => {
          const baseData = {
            label: sample.getResultLabel(),
            notes: sample.getResultNotes(),
            overallResultClass: sample.getOverallResultClass(),
            tests: sample.getAnalysisTestListResults(),
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const resultData = {} as { [key: string]: any }

          prev[index] = baseData

          if (['existing'].includes(sample.sampleType)) {
            return prev
          }

          this.cleanAnalysisTestsAsArray.forEach((analysisTest) => {
            resultData[analysisTest.id] = baseData.tests.find(
              (t) => t.id === analysisTest.id
            )
          })

          prev[index] = {
            ...resultData,
            ...baseData,
          }

          return prev
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {} as { [key: string]: any }
      )

      return results
    },
  },
  methods: {
    getFullSampleTitle(arg: unknown) {
      const sample = arg as ProSample

      // VERSION CHECK: Same or newer than 2.8.4
      if (
        compare(
          this.projectProVersion,
          versionKeys.HIDE_EQUIVALENT_NUMBERS,
          '>='
        )
      ) {
        const sampleToUse = sample.getReferenceSample()

        let title = sampleToUse.sampleTypeID

        if (sampleToUse.webID.origin) {
          title += ` - ${sampleToUse.webID.origin}`
        }

        title += ` - ${sampleToUse.getCombinedTypeTitle()}`

        return title
      }

      // VERSION CHECK: Older than 2.8.4
      else {
        let title = sample.sampleTypeID

        if (sample.webID.origin) {
          title += ` - ${sample.webID.origin}`
        }

        if (sample.options.isInternalEquivalent) {
          title += ` (P${sample.equivalentSampleNumber})`
        }

        title += ` - ${sample.getCombinedTypeTitle()}`

        return title
      }
    },
    getImages(arg: unknown) {
      const sample = arg as ProSample

      if (['assessed', 'postponed', 'existing'].includes(sample.sampleType)) {
        return []
      }

      // If sample is from other project - get images from original sample
      if (sample.otherScreeningID) {
        const relatedSample = this.allSamples.find(
          (s) => s.id === sample.equivalentSampleID
        )

        const { away, close } = relatedSample?.images ?? {
          away: null,
          close: null,
        }

        return [close, away].filter(Boolean)
      }

      // Use current samples images
      const { close } = sample.getReferenceSample().images

      return [close].filter(Boolean)
    },
  },
  components: {
    PDFOnlineTypeBlockWrap,
    PDFRegion,
    PDFOnlineTypeSampleImage,
  },
})
