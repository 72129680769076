import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "TitleLine" }
const _hoisted_3 = { class: "Title" }
const _hoisted_4 = {
  key: 0,
  class: "Interconnections"
}
const _hoisted_5 = {
  key: 0,
  class: "InnerConnections"
}
const _hoisted_6 = ["href", "textContent"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "OuterConnections"
}
const _hoisted_9 = ["href", "textContent"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "SSGMeta" }
const _hoisted_12 = { class: "SSGDetails" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["textContent"]
const _hoisted_15 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_PDFOnlineTypeBlockWrap = _resolveComponent("PDFOnlineTypeBlockWrap")!

  return (_openBlock(), _createBlock(_component_PDFOnlineTypeBlockWrap, {
    titles: _ctx.titles,
    encodedTitles: _ctx.encodedTitles,
    disableContinued: _ctx.disable.continued,
    disableToBeContinued: _ctx.disable.toBeContinued
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        id: _ctx.encodedTitles.type,
        class: "PDFOnlineTypeBlockMain"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.titles.type), 1),
          _createElementVNode("span", {
            class: _normalizeClass(["Status", _ctx.oType.status.className])
          }, _toDisplayString(_ctx.oType.status.text), 3)
        ]),
        (_ctx.categoryItem.innerOTypeLinks.length || _ctx.categoryItem.outerOTypeLinks.length )
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_ctx.categoryItem.innerOTypeLinks.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('SITS_ON')) + ": ", 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryItem.innerOTypeLinks, (innerLink, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: innerLink.idRef
                      }, [
                        _createElementVNode("a", {
                          href: innerLink.idRef,
                          textContent: _toDisplayString(innerLink.title)
                        }, null, 8, _hoisted_6),
                        (index !== _ctx.categoryItem.innerOTypeLinks.length - 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_7, ", "))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.categoryItem.outerOTypeLinks.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('ON_THIS_SITS')) + ": ", 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryItem.outerOTypeLinks, (outerLink, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: outerLink.idRef
                      }, [
                        _createElementVNode("a", {
                          href: outerLink.idRef,
                          textContent: _toDisplayString(outerLink.title)
                        }, null, 8, _hoisted_9),
                        (index !== _ctx.categoryItem.outerOTypeLinks.length - 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, ", "))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_Image, {
            context: _ctx.context,
            image: _ctx.images[0],
            loading: 'eager',
            dimensions: { width: 167, height: 167 },
            transformation: 'square_200'
          }, null, 8, ["context", "image"]),
          _createElementVNode("div", _hoisted_12, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.details, (block) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: block.title
              }, [
                (block.text)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createElementVNode("span", null, _toDisplayString(block.title), 1),
                      (typeof block.text === 'string')
                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(block.isBold ? 'strong' : 'span'), {
                            key: 0,
                            textContent: _toDisplayString(block.text)
                          }, null, 8, ["textContent"]))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(block.text, (item) => {
                            return (_openBlock(), _createElementBlock(_Fragment, {
                              key: item.text
                            }, [
                              (item.title)
                                ? (_openBlock(), _createElementBlock("strong", {
                                    key: 0,
                                    textContent: _toDisplayString(item.title)
                                  }, null, 8, _hoisted_14))
                                : _createCommentVNode("", true),
                              _createElementVNode("span", {
                                textContent: _toDisplayString(item.text)
                              }, null, 8, _hoisted_15)
                            ], 64))
                          }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ])
        ])
      ], 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["titles", "encodedTitles", "disableContinued", "disableToBeContinued"]))
}