import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "PDFOnlineTypeSampleImage" }
const _hoisted_2 = { class: "ImageContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_PDFFloorplanBlock = _resolveComponent("PDFFloorplanBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.titleTag), { key: 0 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Image, {
        context: _ctx.context,
        image: _ctx.getSampleImage(_ctx.referenceSample),
        loading: 'eager',
        dimensions: { width: 167, height: 167 },
        transformation: 'square_400'
      }, null, 8, ["context", "image"]),
      _createVNode(_component_PDFFloorplanBlock, {
        context: _ctx.context,
        sample: _ctx.referenceSample
      }, null, 8, ["context", "sample"])
    ])
  ]))
}